import './App.css';
import { createRoot } from "react-dom/client";
import React from 'react';
import { AuthProvider } from "./auth/AuthContext";
import AppRouter from "./AppRouter";


createRoot(document.getElementById("root")).render(
  <React.StrictMode>
    <AuthProvider>
      <AppRouter />
    </AuthProvider>
  </React.StrictMode>)
  ;
