import React from 'react';
import { Navbar } from '../components/Navbar';

const HomePage = () => {
    return (
        <div className="flex pt-5 overflow-hidde">
            <Navbar />
        </div>
    );
};

export default HomePage;
