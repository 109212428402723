import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Navbar } from '../components/Navbar';
import Collections from '../components/Collections';
import ApiBackend from '../api/ApiBackend';
import CollapsibleJSONBlock from './../components/CollapsibleJSONBlock';


const DatosPage = () => {
    let params = useParams();
    const { database, collection } = params;

    const [inputValue, setInputValue] = useState('');
    const [search, setSearch] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [data, setData] = useState([]);
    const [text, setText] = useState('1 - 1 de - 2');
    const [pages, setPages] = useState(1);
    const [limit, setLimit] = useState(10);

    const [refresh, setRefresh] = useState(false);

    const handleInputChange = (e) => {
        setInputValue(e.target.value);
    }

    const handleButtonClick = () => {
        setSearch(inputValue);
    }

    const handlePageChange = (page) => {
        setCurrentPage(page);
    }

    const getTextForPage = (page, totalRegistros, filteredCount, limit) => {
        const itemsPerPage = limit;
        const startItem = (page - 1) * itemsPerPage + 1;
        const endItem = Math.min(startItem + itemsPerPage - 1, (filteredCount === 0 ? totalRegistros : filteredCount));

        return `${startItem} - ${endItem} de ${totalRegistros} registros ${filteredCount > 0 ? `filtrados por ${filteredCount}` : ''}`;
    }

    useEffect(() => {
        const fetchData = async () => {
            try {
                const resp = await ApiBackend.get(`/databases/${database}/${collection}?page=${currentPage}&limit=${limit}&search=${search}`);

                const { status, message, data: datos } = resp.data;
                if (status) {
                    setData(datos.data);
                    setPages(datos.pages);
                    setText(getTextForPage(currentPage, datos.rows, datos.filteredCount, limit));
                } else {
                    throw new Error(message);
                }
            } catch (error) {
                console.error(error);
            }
        };

        fetchData();
    }, [collection, currentPage, database, search, refresh, limit]); // Agregar currentPage como dependencia
    return (
        <div className="flex pt-5 overflow-hidde">
            <Navbar />
            <div className="relative w-full h-full overflow-y-auto ml-80 mr-7">
                <main>
                    <h1 className="text-5xl text-white text-center mb-5">{params.database}</h1>
                    <Collections database={params.database} />
                    <div className="flex justify-between items-center mt-5">
                        <input
                            type="text"
                            value={inputValue}
                            onChange={handleInputChange}
                            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block pl-2 p-2.5 bg-dark dark:border-gray-600 dark:placeholder-gray-400 dark:text-gray-200 dark:focus:ring-primary-500 dark:focus:border-primary-500 mr-10 w-2/3"
                        />
                        <button
                            onClick={handleButtonClick}
                            className="bg-green-500 hover:bg-green-600 text-white font-bold py-2 px-4 rounded-lg flex items-center"
                        >
                            Buscar
                        </button>
                        <div className="flex justify-end items-center w-1/2">
                            <div className="flex items-center">
                                <h3 className="mr-9">
                                    {text}
                                </h3>
                                <button
                                    onClick={() => handlePageChange(1)}
                                    className={`text-gray-800 font-bold py-2 px-4 rounded-l-lg ${currentPage === 1 ? 'disabled cursor-no-drop bg-gray-500 hover:bg-gray-500 ' : 'bg-gray-300 hover:bg-gray-400'}`}
                                    disabled={currentPage === 1}
                                >
                                    <i className="fas fa-angle-double-left"></i>
                                </button>
                                <button
                                    onClick={() => handlePageChange(currentPage - 1)}
                                    className={`text-gray-800 font-bold py-2 px-4 ${currentPage === 1 ? 'disabled cursor-no-drop bg-gray-500 hover:bg-gray-500 ' : 'bg-gray-300 hover:bg-gray-400'}`}
                                    disabled={currentPage === 1}
                                >
                                    <i className="fas fa-angle-left"></i>
                                </button>
                                <button
                                    onClick={() => handlePageChange(currentPage + 1)}
                                    className={`text-gray-800 font-bold py-2 px-4 ${currentPage === pages ? 'disabled cursor-no-drop bg-gray-500 hover:bg-gray-500 ' : 'bg-gray-300 hover:bg-gray-400'}`}
                                    disabled={currentPage === pages}
                                >
                                    <i className="fas fa-angle-right"></i>
                                </button>
                                <button
                                    onClick={() => handlePageChange(pages)}
                                    className={`text-gray-800 font-bold py-2 px-4 rounded-r-lg ${currentPage === pages ? 'disabled cursor-no-drop bg-gray-500 hover:bg-gray-500 ' : 'bg-gray-300 hover:bg-gray-400'}`}
                                    disabled={currentPage === pages}
                                >
                                    <i className="fas fa-angle-double-right"></i>
                                </button>
                            </div>
                        </div>
                    </div>
                    <br />
                    {data.map((item) => (<CollapsibleJSONBlock key={item._id} json={item} data={{ ...params }} setRefresh={setRefresh} />))}
                </main>
            </div>
        </div>
    );
};

export default DatosPage;
