import { useContext } from "react";
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import { AuthContext } from "./auth/AuthContext";
import HomePage from "./pages/HomePage";
import LoginPage from "./pages/LoginPage";
import CollectionsPage from "./pages/CollectionsPage";
import DatosPage from "./pages/DatosPage";

const AppRouter = () => {
    const { logged } = useContext(AuthContext);
    return (
        <Router>
            <Routes>
                {
                    logged ?
                        <>
                            <Route path="/" element={<HomePage />} />
                            <Route path="/databases/:database" element={<CollectionsPage />} />
                            <Route path="/databases/:database/:collection" element={<DatosPage />} />
                            <Route path="*" element={<Navigate to="/" />} />
                        </>
                        :
                        <>
                            <Route path="/login" element={<LoginPage />} />
                            <Route path="*" element={<Navigate to="/login" />} />
                        </>
                }

            </Routes>
        </Router>
    );
}

export default AppRouter;