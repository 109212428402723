import { useState } from 'react';
import Editor from '@monaco-editor/react';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { atomOneDark } from 'react-syntax-highlighter/dist/esm/styles/hljs'; // Puedes cambiar a otros estilos si lo deseas

import Swal from 'sweetalert2/dist/sweetalert2.js';

import '@sweetalert2/theme-dark/dark.css';

import ApiBackend from '../api/ApiBackend';


const CollapsibleJSONBlock = ({ json, data: { database, collection }, setRefresh }) => {
    const [editing, setEditing] = useState(false);
    const [editedJson, setEditedJson] = useState(JSON.stringify(json, null, 2));
    const toggleEditing = () => {
        setEditing(!editing);
    };

    const handleEditorChange = (value) => {
        setEditedJson(value);
    };

    const handleSave = () => {

        Swal.fire({
            title: "Editar el documento",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Si, Editar!"
        }).then(async (result) => {
            if (result.isConfirmed) {
                const resp = await ApiBackend.put(`/databases/${database}/${collection}`, { json: editedJson });
                const { status, messsage } = resp.data;
                if (status) {
                    setEditing(false);
                    Swal.fire("Editado!", "El documento ha sido editado.", "success");
                } else {
                    Swal.fire("Error!", messsage, "error");

                }
            }
        });
    }

    const handleDelete = () => {
        Swal.fire({
            title: "Eliminar Registro",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Si, Eliminar!"
        }).then(async (result) => {
            if (result.isConfirmed) {
                const dataJ = JSON.parse(editedJson);
                const _id = dataJ._id;
                const resp = await ApiBackend.delete(`/databases/${database}/${collection}/${_id}`);

                const { status, messsage } = resp.data;

                if (status) {
                    setRefresh(true);
                    Swal.fire("Eliminado!", "Registro eliminado", "success");
                } else {
                    Swal.fire("Error!", messsage, "error");
                }
            }
        });
    }

    return (
        <div className="mb-5">


            {editing ? (
                <div style={{ backgroundColor: "#1e1e1e" }}>
                    <div className="pl-10 pb-2">
                        <button onClick={handleSave} className="mr-2"><i className="fa fa-save"></i></button>
                        <button onClick={handleDelete}><i className="fa fa-trash"></i></button>
                    </div>
                    <hr />
                    <Editor
                        height={500}
                        defaultLanguage="JSON"
                        language="JSON"
                        theme="vs-dark"
                        value={editedJson}
                        options={{
                            selectOnLineNumbers: true,
                            minimap: {
                                enabled: false // Deshabilita el minimapa
                            },
                            scrollbar: {
                                vertical: 'auto', // Muestra la barra de desplazamiento vertical automáticamente
                                horizontal: 'visible' // Muestra la barra de desplazamiento horizontal siempre
                            },
                            automaticLayout: true, // Ajusta automáticamente el diseño del editor al cambiar el tamaño de la ventana
                            fontSize: 16, // Establece el tamaño de fuente en 16px
                            tabSize: 2, // Establece el tamaño de tabulación en 2 espacios
                            insertSpaces: true, // Utiliza espacios en lugar de tabulaciones para la sangría
                            fontFamily: 'Consolas, monospace', // Establece la fuente del editor
                            wordWrap: 'on' // Habilita el ajuste de línea automático
                        }}
                        onChange={handleEditorChange}
                    />
                </div>
            ) : (
                <div style={{ backgroundColor: "rgb(40, 44, 52)" }}>
                    <div className="pl-3 pb-2">
                        <button onClick={toggleEditing} className="mr-2"><i className={editing ? 'fa fa-save' : 'fa fa-edit'}></i></button>
                        <button onClick={handleDelete}><i className="fa fa-trash"></i></button>
                    </div>
                    <hr />
                    <SyntaxHighlighter language="json" style={atomOneDark} showLineNumbers={true} showInlineLineNumbers={true} wrapLines={true}>
                        {editedJson}
                    </SyntaxHighlighter>
                </div>
            )}
        </div>
    );
}

export default CollapsibleJSONBlock;