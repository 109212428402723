import { Link } from "react-router-dom";


function formatSize(sizeInBytes) {
    const kilobyte = 1024;
    const megabyte = kilobyte * 1024;
    const gigabyte = megabyte * 1024;

    if (sizeInBytes < kilobyte) {
        return sizeInBytes + ' bytes';
    } else if (sizeInBytes < megabyte) {
        return (sizeInBytes / kilobyte).toFixed(2) + ' KB';
    } else if (sizeInBytes < gigabyte) {
        return (sizeInBytes / megabyte).toFixed(2) + ' MB';
    } else {
        return (sizeInBytes / gigabyte).toFixed(2) + ' GB';
    }
}

export function LinkDatabases({ database }) {
    return (
        <li >
            <Link
                to={`/databases/${database.name}`}
                className="flex items-center p-2 text-base rounded-lg group hover:bg-gray-700"
            >
                <i className="fas fa-database text-2xl"></i>
                <div className="ml-3">
                    <h4 className="m-0 text-white">{database.name}</h4>
                    <small className="text-white">{formatSize(database.sizeOnDisk)}</small>
                </div>
            </Link>
        </li>
    );
}

export default LinkDatabases;
