
import { useState, useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';
import ApiBackend from '../api/ApiBackend';
import { AuthContext } from "../auth/AuthContext";
import LinkDatabases from './LinkDatabases';

export const Navbar = () => {
    const { logout } = useContext(AuthContext);
    const [databases, setDatabases] = useState([]);
    const [inputValue, setInputValue] = useState('');

    const [databasePrincipal, setDatabasePrincipal] = useState([]);

    const getListDatabases = async () => {
        const response = await ApiBackend.get('/databases');
        const { status, data } = JSON.parse(response.request.response);
        if(status){
            return data;
        }

        return [];
    }


    useEffect(() => {
        async function fetchData() {
            setDatabases(await getListDatabases());
            setDatabasePrincipal(await getListDatabases());
        }
        fetchData();
    }, []);

    function handleKeyUp(event) {
        setInputValue(event.target.value.toUpperCase());

        if (event.target.value === '') {
            setDatabases(databasePrincipal);
        } else {
            const filterDatabases = databasePrincipal.filter((database) => {
                return database.name.toUpperCase().includes(event.target.value.toUpperCase());
            });
            setDatabases(filterDatabases);
        }

    }

    return (
        <aside
            id="sidebar"
            className="fixed top-0 left-0 z-20 flex flex-col flex-shrink-0 h-full font-normal duration-75 lg:flex transition-width w-72"
            aria-label="Sidebar"
        >
            <div className="relative flex flex-col flex-1 min-h-0 pt-0 bg-white border-r border-gray-200 bg-dark">
                <div className="flex flex-col flex-1 pt-5 pb-4 overflow-y-auto bg-dark">
                    <div className="flex-1 px-3 space-y-1 bg-white divide-y divide-gray-200 bg-dark">
                        <ul className="pb-2 space-y-2 bg-dark ">
                            <li>
                                <div className="flex items-end justify-center">
                                    <Link to="/" className="text-5xl mr-5">
                                    INICIO
                                    </Link>

                                    <button type="button" onClick={logout}>
                                        <i className="fas fa-power-off"></i>
                                    </button>

                                </div>
                            </li>
                            <li>
                                <form action="#" method="GET" className="">
                                    <label htmlFor="mobile-search" className="sr-only">
                                        Buscar...
                                    </label>
                                    <div className="relative">
                                        <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                                            <svg
                                                className="w-5 h-5 text-gray-500"
                                                fill="currentColor"
                                                viewBox="0 0 20 20"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <path
                                                    fillRule="evenodd"
                                                    d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                                                    clipRule="evenodd"
                                                />
                                            </svg>
                                        </div>
                                        <input
                                            type="text"
                                            name="email"
                                            id="mobile-search"
                                            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 pl-10"
                                            placeholder="Buscar..."
                                            onChange={handleKeyUp}
                                            value={inputValue}
                                        />
                                    </div>
                                </form>
                            </li>

                            {databases.map((database) => (<LinkDatabases key={database.name} database={database} />))}
                        </ul>
                    </div>
                </div>
            </div>
        </aside>
    );
}