import { Link } from "react-router-dom";
import { useEffect, useState } from 'react';
import ApiBackend from '../api/ApiBackend';

const getDatabase = async (database) => {
    const response = await ApiBackend.get(`/databases/${database}`);
    const { status, data, message } = JSON.parse(response.request.response);
    if (status) {
        return data;
    } else {
        console.log(message);
        return [];
    }
}


const Collections = ({ database }) => {
    const [colections, setColections] = useState([]);

    useEffect(() => {
        getDatabase(database).then((data) => {
            setColections(data);
        });
    }, [database]);
    return (
        <div className="flex flex-wrap justify-center items-center">
            {colections.map((colection) => {
                return (
                    <Link to={`/databases/${database}/${colection}`} key={colection} className="m-2 text-white">
                        {colection}
                    </Link>
                );
            })}
        </div>
    );
};

export default Collections;
