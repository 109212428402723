import { useState, useContext } from "react";
import { AuthContext } from "../auth/AuthContext";

const LoginPage = () => {
    const { login } = useContext(AuthContext);
    const [credentials, setCredentials] = useState({ username: '', password: '' });
    const [error, setError] = useState('');
    const [showPassword, setShowPassword] = useState(false);

    const handleChange = async (e) => {
        try {
            e.preventDefault();
            await login(credentials);
        } catch (error) {
            console.log(error);
            setError(error);
        }
    };

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    return (
        <div className="flex justify-center items-center h-screen">
            <form onSubmit={handleChange} className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4 w-96 bg-dark">
                <h1 className="text-2xl text-white font-bold mb-4 text-center">Iniciar Sesión</h1>
                <div className="mb-4">
                    <label className="block text-white text-sm font-bold mb-2" htmlFor="username">
                        Usuario :
                    </label>
                    <input
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        id="username"
                        type="text"
                        placeholder="Username"
                        onChange={(e) => setCredentials({ ...credentials, username: e.target.value })}
                        required
                    />
                </div>
                <div className="mb-6">
                    <label className="block text-white text-sm font-bold mb-2" htmlFor="password">
                        Contraseña :
                    </label>
                    <div className="relative">
                        <input
                            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                            id="password"
                            type={showPassword ? "text" : "password"}
                            placeholder="Password"
                            onChange={(e) => setCredentials({ ...credentials, password: e.target.value })}
                            required
                        />
                        <button
                            type="button"
                            className="absolute right-2 top-2.5 text-gray-500"
                            onClick={togglePasswordVisibility}
                        >
                            {showPassword ? (
                                <i className="fas fa-eye-slash"></i>
                            ) : (
                                <i className="fas fa-eye"></i>
                            )}
                        </button>
                    </div>
                </div>
                <div className="flex items-center justify-center">
                    <button
                        className="hover:bg-blue-700 text-white font-bold py-2 px-4 rounded bg-blue-500"
                        type="submit"
                    >
                        Ingresar
                    </button>
                </div>
                {error && <p className="text-red-500 text-xs italic mt-2">{error}</p>}
            </form>
        </div>
    );
};

export default LoginPage;
