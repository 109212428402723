import axios from "axios";

const ApiBackend = axios.create({
    // baseURL: "http://localhost:7001/api",
    baseURL: "https://backend.factuweb.mx/api",
    headers: {
        "Content-type": "application/json"
    }
});

ApiBackend.interceptors.request.use(
    config => {
        const token = localStorage.getItem("token");
        if (token) {
            config.headers.authorization = token;
        }
        return config;
    },
    error => {
        return Promise.reject(error);
    }
);  

export default ApiBackend;