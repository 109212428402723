import { createContext, useReducer, useEffect } from "react";
import ApiBackend from "../api/ApiBackend";
import { types } from "../types/types";
import { authReducer } from "./authReducer";
import { Navigate } from "react-router-dom";


export const AuthContext = createContext({});

const authInitialState = {
    user: null,
    logged: false,
};

export const AuthProvider = ({ children }) => {
    const [state, dispatch] = useReducer(authReducer, authInitialState);
    useEffect(() => {
        const verifyToken = async () => {
            try {
                const resp = await ApiBackend.get('/auth/verify');
                const { status, data } = resp.data;

                if (status) {
                    dispatch({
                        type: types.login,
                        payload: data
                    });
                }

            } catch (error) {
                if (error.request.response === "") {

                } else {
                    const res = JSON.parse(error.request.response);
                    console.log(res.message);
                    // throw res.message;
                }
            }
        }
        verifyToken();
    }, []);



    const login = async ({ username, password }) => {
        try {

            const resp = await ApiBackend.post('/auth/login', { username, password });
            const { status, message, data } = resp.data;

            if (!status) throw (message);
            localStorage.setItem('token', data.token);
            localStorage.setItem('user', data.user);
            dispatch({
                type: types.login,
                payload: resp.data
            });
            <Navigate to="/" />
        } catch (error) {
            const res = JSON.parse(error.request.response);
            throw res.message;
        }
    }

    const logout = () => {
        localStorage.clear();
        dispatch({
            type: types.logout
        });
        <Navigate to="/" />
    }




    return (
        <AuthContext.Provider value={{
            login,
            logout,
            ...state
        }}>
            {children}
        </AuthContext.Provider>
    );
}