import { useParams } from 'react-router-dom';
import { Navbar } from '../components/Navbar';
import Collections from '../components/Collections';

const CollectionsPage = () => {
    let params = useParams();
    return (
        <div className="flex pt-5 overflow-hidde">
            <Navbar />
            <div className="relative w-full h-full overflow-y-auto ml-80 mr-7">
                <main>
                    <h1 className="text-5xl text-white text-center mb-5">{params.database}</h1>
                    <Collections database={params.database} />

                </main>
            </div>
        </div>
    );
};

export default CollectionsPage;
